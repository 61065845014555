import React from 'react'
import styled from '@emotion/styled'
import { useColorMode } from 'theme-ui'

import { graphql, useStaticQuery } from 'gatsby'

import Layout from '@components/Layout'
import { Section, SectionHeader } from '@components/Section'
import Seo from '@components/SEO'

import mediaqueries from '@styles/media'

import {
  SectionParagraph,
  SectionHeading,
  SectionSmallHeading,
} from '@components/Section'
import { CallToButton } from '@components/Button'

const ContactPage = () => {
  return (
    <Layout>
      <Seo
        image="/images/og/contact.png"
        url="/contact"
        title="Contact"
        description="Contact us to take your business to the next level with our marketing solutions."
      />
      <Section>
        <div style={{ marginTop: '100px' }}>
          {/* <SectionHeader>Business Information</SectionHeader> */}
          <SectionHeading.h1>Contact</SectionHeading.h1>
          <SectionParagraph>Ready to take your business to the next level with our marketing solutions?</SectionParagraph>

          <CallToButton
            to="/schedule"
            title="Schedule a call"
            aria-label="Schedule a call"
          >
            Schedule a call!
          </CallToButton>

          <ContactInfoGrid>
            <div>
              <ContactInfo />
            </div>

            <ContactAddress>
              <SectionSmallHeading>Headquarters</SectionSmallHeading>
              <address>
                Irvine, California
                <br />
                United States
              </address>
            </ContactAddress>
            <CompanyMap />
            {/* <ContactForm /> */}
          </ContactInfoGrid>
        </div>
      </Section>
    </Layout>
  )
}

const CompanyMap = () => {
  const [colorMode] = useColorMode()
  const isDark = colorMode === `dark`

  const data = useStaticQuery(graphql`
    query GetMaps {
      lightMap: file(relativePath: { eq: "maps/light-map.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      darkMap: file(relativePath: { eq: "maps/dark-map.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const lightMapImage = data.lightMap.childImageSharp.fluid
  const darkMapImage = data.darkMap.childImageSharp.fluid

  const googleMapsUrl = `https://www.google.com/maps/place/33°40'15.7"N+117°50'42.1"W/@33.671038,-117.8472167,17z`

  return (
    <CompanyMapLink
      lightMapImage={lightMapImage.src}
      darkMapImage={darkMapImage.src}
      isDark={isDark}
      href={googleMapsUrl}
      target="_blank"
      rel="noopener norefferer"
      title="Open map in new tab"
      className="m1xzjln5"
    >
      <CompanyMapIcon isDark={isDark}>
        <svg width="12" height="12" viewBox="0 0 100 100">
          <path
            className="b"
            d="M3.62,26.57c.3-.68.6-1.35.92-2,.43-.86.88-1.7,1.35-2.51s.92-1.54,1.43-2.3a1.78,1.78,0,0,1,1.56-.92H42.7a5.88,5.88,0,0,1,2.21.43l.11,0a6,6,0,0,1,3.27,3.29,6,6,0,0,1,0,4.65A6.08,6.08,0,0,1,45,30.55h0A6,6,0,0,1,42.7,31H4.46a2,2,0,0,1-.63-.11,1.78,1.78,0,0,1-1-2.29c.26-.68.53-1.36.83-2Z"
          />
          <path
            className="c"
            d="M.15,47.6c0-.15,0-.42-.06-.77S0,46,0,45.43H0q0-.9,0-1.44c0-.38,0-.87,0-1.48s0-1,.07-1.45,0-.62.06-.82,0-.36.07-.69A1.78,1.78,0,0,1,2,37.81H22.6a6.07,6.07,0,0,1,2.21.42l.11,0a6.21,6.21,0,0,1,2,1.32,5.9,5.9,0,0,1,1.31,2,6,6,0,0,1,0,4.65,6,6,0,0,1-1.31,2,6.21,6.21,0,0,1-2,1.32h0A6,6,0,0,1,22.6,50H2a1.78,1.78,0,0,1-1.77-1.6c0-.35-.06-.6-.07-.72v0Z"
          />
          <path
            className="b"
            d="M58.29,88H44a44.24,44.24,0,0,1-8.08-.75,43.92,43.92,0,0,1-18.38-8.16,1.78,1.78,0,0,1,1-3.24l38.76.12h.32a6.06,6.06,0,0,1,4.1,1.77A6.09,6.09,0,0,1,63.48,82a6.07,6.07,0,0,1-.37,2.08,5.83,5.83,0,0,1-1,1.83h0a6.17,6.17,0,0,1-1.54,1.31,6.23,6.23,0,0,1-1.82.72,1.9,1.9,0,0,1-.41,0Z"
          />
          <path
            className="c"
            d="M88,77.51v8.6a1.78,1.78,0,0,1-1.78,1.78H75.42a6.13,6.13,0,0,1-2.22-.42l-.1,0a6.15,6.15,0,0,1-2-1.32,6.22,6.22,0,0,1-1.31-2,6,6,0,0,1-.46-2.32,6.13,6.13,0,0,1,.46-2.33,6.28,6.28,0,0,1,1.31-2,6.15,6.15,0,0,1,2-1.32h0a6.08,6.08,0,0,1,2.32-.46H86.18A1.78,1.78,0,0,1,88,77.51Z"
          />
          <path
            className="c"
            d="M88,58.55v8.6a1.78,1.78,0,0,1-1.78,1.78H8.76a1.76,1.76,0,0,1-1.48-.8q-.81-1.22-1.5-2.43c-.44-.76-.88-1.6-1.33-2.5-.32-.66-.61-1.29-.88-1.9l0-.09c-.29-.67-.55-1.34-.8-2a1.8,1.8,0,0,1-.13-.66,1.79,1.79,0,0,1,1.79-1.78H86.18A1.78,1.78,0,0,1,88,58.55Z"
          />
          <path
            className="b"
            d="M87.8,40.23c0,.2,0,.47.06.82s0,.9.07,1.45,0,1.1,0,1.48v4.21A1.77,1.77,0,0,1,86.18,50H41.42a6.09,6.09,0,0,1-2.22-.42l-.1,0a6.15,6.15,0,0,1-2-1.32,6.28,6.28,0,0,1-1.31-2,6.11,6.11,0,0,1,0-4.65,6.22,6.22,0,0,1,1.31-2,6.15,6.15,0,0,1,2-1.32h0a6.19,6.19,0,0,1,2.32-.46H86a1.76,1.76,0,0,1,1.77,1.59c0,.4.07.68.08.82Z"
          />
          <path
            className="c"
            d="M82.07,22.07c.47.81.92,1.65,1.35,2.51.32.64.63,1.31.92,2s.54,1.28.78,1.91a1.75,1.75,0,0,1,.17.75A1.79,1.79,0,0,1,83.51,31H62.16a6.13,6.13,0,0,1-2.22-.43l-.1,0a6,6,0,0,1-2-1.32,6.28,6.28,0,0,1-1.31-2,6.11,6.11,0,0,1,0-4.65,6.22,6.22,0,0,1,1.31-2,6,6,0,0,1,2-1.31h0a6,6,0,0,1,2.31-.47H79.09a1.74,1.74,0,0,1,1.47.79c.54.8,1,1.61,1.51,2.42Z"
          />
          <path
            className="b"
            d="M69.3,12.06H18.67a1.76,1.76,0,0,1-1.42-.7,1.78,1.78,0,0,1,.34-2.5A45.2,45.2,0,0,1,22.7,5.53,43.1,43.1,0,0,1,28.2,3,43.63,43.63,0,0,1,36,.74,44,44,0,0,1,52,.74,44.05,44.05,0,0,1,65.26,5.53a44.62,44.62,0,0,1,5,3.26,1.81,1.81,0,0,1,.79,1.48,1.79,1.79,0,0,1-1.78,1.79Z"
          />
        </svg>
      </CompanyMapIcon>
    </CompanyMapLink>
  )
}

const EmailSvgIcon = () => {
  const [colorMode] = useColorMode()
  const isDark = colorMode === `dark`
  const strokeColor = isDark ? '#fff' : '#000'
  return (
    <svg width="24" height="20" className="l27oob1">
      <g
        fill="none"
        fillRule="evenodd"
        stroke={strokeColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      >
        <path d="M1 11v6a2 2 0 002 2h18a2 2 0 002-2v-6"></path>
        <path d="M23 6V3a2 2 0 00-2-2H3a2 2 0 00-2 2v3l11 6 11-6z"></path>
      </g>
    </svg>
  )
}

const ContactInfo = () => {
  const contactSections = [
    {
      name: 'New Business',
      link: 'sales@auotiv.com',
    },
    // {
    //   name: 'Want To Join Us?',
    //   link: 'careers@auotiv.com'
    // },
  ]
  const contactInfo = contactSections.map((contactSection) => {
    const mailToLink = `mailto:${contactSection.link}`
    return (
      <li>
        <SectionSmallHeading>{contactSection.name}</SectionSmallHeading>
        <EmailSvgIcon />
        <EmailLink href={mailToLink}>{contactSection.link}</EmailLink>
      </li>
    )
  })
  return <ContactInfoList>{contactInfo}</ContactInfoList>
}

const ContactForm = () => {
  return (
    <ContactFormStyle>
      <SectionSmallHeading>Contact Us</SectionSmallHeading>
      <form
        method="post"
        action="https://getform.io/f/c6aa8b8d-0d10-46bb-a7a6-fddba1290f5b"
      >
        <label>
          Email
          <input type="email" name="email" />
        </label>
        <label>
          Name
          <input type="text" name="name" />
        </label>
        <label>
          Message
          <input type="text" name="message" />
        </label>
        <div
          class="g-recaptcha"
          data-sitekey="6LeOvbYjAAAAAOWe6Lym-i5QJor2tRddjfbGYjBc"
        ></div>
        <button type="submit" class="btn btn-primary">
          Submit
        </button>
      </form>
    </ContactFormStyle>
  )
}

const ContactAddress = styled.div`
  color: ${(p) => p.theme.colors.articleText};

  address {
    font-style: normal;
  }

  div {
    margin-bottom: 20px;
  }
`

const CompanyMapIcon = styled.div`
  width: 2rem;
  height: 2rem;
  border: 2px solid var(--color-text-contrast);
  background: ${(p) => (p.isDark ? '#fff' : '#000')};
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 0 rgba(218, 54, 84, 0.4);
  // animation: pulse-m14rsj8x 2s infinite;
  position: absolute;
  left: 48%;
  top: 47%;

  svg {
    fill: ${(p) => (p.isDark ? '#000' : '#fff')};
  }
`

const CompanyMapLink = styled.a`
  background-image: url(${(p) =>
    p.isDark ? p.darkMapImage : p.lightMapImage});

  // transition: background 0.3s ease;
  // transition: background 0.3s ease-in-out, opacity 0.3s ease-in-out;

  height: 450px;
  width: 100%;
  background-size: cover;
  background-position: 50%;
  position: relative;
`

const EmailLink = styled.a`
  color: ${(p) => p.theme.colors.flashyAccent};
  margin-left: 10px;
`

const ContactInfoList = styled.ul`
  li {
    list-style-type: none;
    margin-bottom: 50px;
  }
`

const ContactFormStyle = styled.ul`
  label {
    display: block;
  }

  input {
    display: block;
  }

  button {
    position: relative;
    display: inline-block;
    color: #fff;
    background-color: ${(p) => p.theme.colors.flashyAccent};
    border-radius: 28px;
    font-size: 14px;
    padding: 12px 25px;
    margin: 15px 0 0;
  }
`

const ContactInfoGrid = styled.div`
  display: grid;
  margin-top: 45px;
  grid-gap: 30px;
  // grid-template-columns: repeat(auto-fit,minmax(220px,1fr));
  grid-template-columns: 3fr 2fr 4fr;

  ${mediaqueries.tablet`
    grid-template-columns: 1fr;
  `};
`

export default ContactPage
